<template>
  <div
    :class="
      'col-xl-' +
      prodotto.colonne +
      ' col-md-' +
      prodotto.colonne +
      ' col-sm-12'
    "
  >
    <card
      :gradient="index % 2 === 0 ? 'info' : 'secondary'"
      class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
      :style="{ minHeight: '96%' }"
      header-classes="bg-transparent"
      :header-styles="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100px',
        backgroundImage: 'url(' + prodotto.urlImage + ')',
      }"
      body-classes="px-lg-7"
      footer-classes="bg-transparent"
    >
      <template v-slot:header>
        <h4
          :class="
            index % 2 === 0
              ? 'text-uppercase ls-1 text-white py-3 mb-0'
              : 'text-uppercase ls-1 text-black py-3 mb-0'
          "
        >
          {{ prodotto.descrizione }}
        </h4>
      </template>
      <span
        :class="
          index % 2 === 0 ? 'text-white text-left' : 'text-black text-left'
        "
        v-html="prodotto.descrizioneLunga"
      ></span>
      <template v-slot:footer>
        <a
          @click="goToAgente()"
          style="cursor: pointer"
          :class="index % 2 === 0 ? 'text-white' : 'text-black'"
          >Contattaci</a
        >
      </template>
    </card>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "ProdottoComponent",
  //I am passing the name of the Component as a prop
  props: {
    prodotto: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const goToAgente = function () {
      router.push("/agente");
    };
    return {
      goToAgente,
    };
  },
};
</script>
